import React from "react";
import {
  DASHBOARD_PATH,
  GROUPS_PATH,
  IMPACT_PATH,
  PROFILES_PATH,
} from "../../constants/route.constant";
import { Link, useLocation } from "react-router-dom";
import "react-modern-drawer/dist/index.css";

const MenuSection = () => {
  const location = useLocation();
  const active = location?.pathname;

  return (
    <div className="justify-center items-center mx-5 hidden lg:flex">
      <ul className="list-none flex justify-center items-center gap-10 text-[16px] font-sans font-semibold">
        <li
          className={`cursor-pointer py-1 text-center  text-theme ${
            active === DASHBOARD_PATH
              ? "border-b-2 border-theme"
              : "line-animation"
          }`}
        >
          <Link to={DASHBOARD_PATH}>Dashboard</Link>
        </li>
        <li
          className={`cursor-pointer py-1 text-center  text-theme ${
            active.includes("profiles")
              ? "border-b-2 border-theme"
              : "line-animation"
          }`}
        >
          <Link to={PROFILES_PATH}>Individual</Link>
        </li>
        <li
          className={`cursor-pointer py-1 text-center  text-theme ${
            active.includes("groups")
              ? "border-b-2 border-theme"
              : "line-animation"
          }`}
        >
          <Link to={GROUPS_PATH}>Groups</Link>
        </li>
        <li
          className={`cursor-pointer py-1 text-center  text-theme ${
            active.includes("impact")
              ? "border-b-2 border-theme"
              : "line-animation"
          }`}
        >
          <Link to={IMPACT_PATH}>Impact</Link>
        </li>
      </ul>
    </div>
  );
};

export default MenuSection;